<template>
  <footer class="text-center">
    <p class="my-0">
      © {{ year }} Forjado por
      <a href="https://wize.pe/" target="_blank" class="font-weight-bold"
        >Wize</a
      >
    </p>
  </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            year: new Date().getFullYear()
        }
    }
};
</script>

<style lang="stylus">
@import "../Styles/footer.styl"
</style>
