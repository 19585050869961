import Vue from 'vue'
import VueGtag from "vue-gtag";
import '@/core/plugins/vuelidate'

import App from './App.vue'

Vue.config.productionTip = false

import router from './router'
import store from './store'

import '@/core/plugins/bootstrap'
import '@/core/styles/app.styl'
import '@/core/styles/styles.css'
import '@/core/plugins/vuelidate'
import '@/core/plugins/smooth-scroll'
import '@/core/plugins/directives'

// Hamburger Css https://jonsuh.com/hamburgers/
import 'hamburgers/dist/hamburgers.min.css'

if (process.env.VUE_APP_NODE_ENV == "production") {
  Vue.use(VueGtag, {
      config: {
          id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
      }
  });
}

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
