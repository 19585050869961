export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      showLocal: false,
    }
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
  watch: {
    show(newVal) {
      this.showLocal = newVal;
    }
  },
  async created() {
    this.showLocal = this.show;
  }
}
