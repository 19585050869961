<template>
  <section class="animate__animated animate__fadeIn">
    <Loader :loading="isLoading"/>
    <Header :business="business"/>
    <ComplaintForm :business="business"/>
  </section>
</template>

<script>
import {Header, Loader} from "@/core/components";
import ComplaintForm from '../Components/ComplaintForm.vue'
import {BusinessService} from "@/core/services"
import {mapGetters} from "vuex";

export default {
  components: {
    Header,
    ComplaintForm,
    Loader
  },
  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading'
    })
  },
  data() {
    return {
      loading: true,
      business: {}
    }
  },
  async beforeMount() {
    try {
      await this.$store.dispatch("app/loading", true);
      const response = await BusinessService.getBusiness(this.$route.params.slug)
      this.business = response.payload;
      document.documentElement.style.setProperty('--color-primary', this.business.primaryColor ? this.business.primaryColor : '#0070C9');
    } catch (ex) {
      console.error('Error getting business info', ex)
      await this.$router.push({name: "home"});
    } finally {
      await this.$store.dispatch("app/loading", false);
    }
  }
};
</script>

<style lang="stylus">
</style>
