<template>
  <section class="observations">
    <h3 class="subtitle my-0">
      {{ title }}
    </h3>

    <ul class="list-unstyled pl-0">
      <li>
        La respuesta a este reclamo o queja será enviada al correo indicado en
        el presente formulario.
      </li>
      <li>
        El plazo máximo de atención es de 30 días calendario desde su
        presentación, el cual podrá extenderse excepcionalmente de acuerdo a la
        complejidad del reclamo o queja lo cual será informado oportunamente
        mediante un previo aviso al USUARIO.
      </li>
      <li>
        Con el envío del presente documento EL USUARIO valida la información
        consignada y declara haber sido debidamente informado por
        {{ business.name }} sobre el procedimiento, plazo de atención y medio de
        respuesta a su reclamo o queja.
      </li>
      <li>
        En caso el reclamo resulte procedente o improcedente, será notificado a
        través del correo electrónico indicado en este formulario.
      </li>
      <li>
        Su HOJA DE RECLAMACIÓN/QUEJA así como el CÓDIGO para su seguimiento, le
        será enviado a su correo electrónico por el cual nos está enviando la
        presente comunicación.
      </li>
      <li class="mb-0">
        La formulación del reclamo no impide acudir a otras vías de solución de
        controversias ni es requisito previo para interponer una denuncia ante
        el INDECOPI.
      </li>
    </ul>

    <h4>MUCHAS GRACIAS POR SU COMUNICACIÓN,</h4>

    <h5 class="mb-0">
      Atentamente,
      <br />
      {{ business.name }}
    </h5>
  </section>
</template>

<script>
export default {
    props: {
        title: String,
        business: Object
    }
};
</script>

<style lang="stylus">
@import "../Styles/observations.styl"
</style>
