<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "LoginView",
  components: {},
  data() {
    return {}
  },
};
</script>

<style scoped>
</style>
