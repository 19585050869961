<template>
  <header class="header bg-white header-shadow">
    <div class="container d-flex justify-content-between align-items-center h-100">
      <section class="w-100 d-flex justify-content-between align-items-center">
        <!-- Logo -->
        <a :href="business.website" target="_blank" class="d-flex align-items-center mb-0">
          <img
            :src="business.logo ? business.logo.url: require('@/core/assets/images/logo-zet.png') "
            :alt="business.name"
            class="logo"
          />
        </a>

        <!-- Hamburger -->
        <button
          class="hamburger hamburger--collapse d-md-none mr-3"
          :class="{ 'is-active': showNav }"
          type="button"
          @click="showNav = !showNav"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </section>

      <!-- Nav -->
      <nav
        class="nav bg-white w-100 d-md-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center"
        :class="{ 'nav--active': showNav }"
      >
        <div class="d-flex mt-3 mt-md-0">
          <a :href="business.website" target="_blank" class="d-flex align-items-center link text-decoration-none"
            >Visitar web</a>

          <router-link :to="{name: 'home'}" target="_blank" class="button button-primary button-header"
            >Conoce ZET</router-link>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    business: Object,
  },
  data() {
    return {
      showNav: false
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/components/header.styl';
</style>
