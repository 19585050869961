<template>
  <section>
    <router-view></router-view>
  </section>
</template>

<script>

export default {
  name: "ComplaintView"
}
</script>

<style scoped lang="stylus">
</style>
