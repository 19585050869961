<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    dialog-class="container-description"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    content-class="modal-description"
    id="complaint-modal"
  >
    <section>
      <h2 class="modal-title text-center my-0">
        ¡Tu reclamo ha sido enviado correctamente!
      </h2>

      <p class="modal-description">
        Recuerda que existe un plazo de 30 días calendario para la atención de
        un reclamo. Su atención no debe estar condicionada a ningún pago. Por
        otro lado, el proveedor no está obligado a responder a una queja; sin
        embargo, puede realizar acciones que le permitan conservar su clientela.
      </p>

      <div class="text-center" @click="hide()">
        <button type="button" class="button button-primary">Entendido</button>
      </div>
    </section>
  </b-modal>
</template>


<script>
import { ModalMixin } from "@/core/mixins";

export default {
  name: "ComplaintModal",
  mixins: [ModalMixin],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="styl">
@import "../Styles/complaint-modal.styl";
</style>