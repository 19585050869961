const SSO_STATE_KEY = 'ssoState';
const USER_KEY = 'user';
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const BUSINESS_KEY = 'business';

function getUser() {
  const user = localStorage.getItem(USER_KEY) || '{}'
  return JSON.parse(user)
}

function setUser(user) {
  localStorage.setItem(USER_KEY, JSON.stringify(user))
}

function clearUser() {
  localStorage.removeItem(USER_KEY)
}

function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

function setRefreshToken(accessToken) {
  localStorage.setItem(REFRESH_TOKEN_KEY, accessToken);
}

function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

function clearRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

function setSsoState(ssoState) {
  localStorage.setItem(SSO_STATE_KEY, ssoState);
}

function getSsoState() {
  return localStorage.getItem(SSO_STATE_KEY);
}

function clearSsoState() {
  localStorage.removeItem(SSO_STATE_KEY)
}

function clearAll() {
  localStorage.clear();
}

function setBusiness(business) {
  localStorage.setItem(BUSINESS_KEY, JSON.stringify(business));
}

function getBusiness() {
  return localStorage.getItem(BUSINESS_KEY);
}

function clearBusiness() {
  localStorage.removeItem(BUSINESS_KEY)
}

export default {
  setSsoState,
  getSsoState,
  clearSsoState,
  getUser,
  setUser,
  clearUser,
  getAccessToken,
  setAccessToken,
  clearAccessToken,
  getRefreshToken,
  setRefreshToken,
  clearRefreshToken,
  clearAll,
  getBusiness,
  setBusiness,
  clearBusiness
}
