<template>
  <div>
    <a href="" class="button-outline-light mr-3" @click.prevent="onSsoSubmit()">Acceder</a>
  </div>
</template>

<script>

export default {
    name: 'AccessButton',

    methods: {
      onSsoSubmit() {
        window.location.href = process.env.VUE_APP_ADMIN_URL;
      }
    }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables"

.button-outline-light
  font roboto-regular

</style>
