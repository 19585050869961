/**
 * Common const to handle http requests and responses
 * src/http.js
 */
import axios from 'axios'
import qs from 'qs'
import {LocalStorage} from './core/utils'
import router from '@/router'
import store from '@/store'

/**
 *
 * parse error response
 */
function parseError(messages) {
  // error
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject(new Error(messages[0]))
    }
    if (messages instanceof Object && messages.detail) {
      // JWT DRF returns an object error response
      return Promise.reject(messages.detail)
    } else {
      return Promise.reject(messages)
    }
  } else {
    return Promise.reject(new Error('Error'))
  }
}

/**
 * parse response
 */
function parseBody(response) {
  if (response.status === 200) {
    if (response.headers['content-disposition'])
      return response;
    else
      return response.data;
  } else {
    return parseError(response.data ? response.data.messages : response)
  }
}

/**
 * axios instance
 */
let instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  paramsSerializer: function (params) {
    return qs.stringify(params, {indices: false})
  }
})

const requestUseConfig = (config) => {
  if (LocalStorage.getAccessToken() && !config.removeAuth) {
    config.headers['Authorization'] = 'Bearer ' + LocalStorage.getAccessToken();
    config.headers['Business-Id'] = store.getters['app/getBusiness'] ? store.getters['app/getBusiness'].id : null;
  }
  return config
}

const responseUseError = function (error) {
  let originalRequest = error.config
  const user = JSON.parse(localStorage.getItem('user'));
  if (!error.response || (error.response && error.response.status === 401 && user && !originalRequest._retry)) {
    originalRequest._retry = true
    store.dispatch('auth/logout');
    router.push({name: 'login'})
  }
  return parseError(error.response.data)
}

// request header
instance.interceptors.request.use(requestUseConfig, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return parseBody(response);
}, responseUseError)

const http = instance

export {http}
