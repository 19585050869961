import {http} from '@/http'
import { Util } from "@/core/utils";
export default {

  saveComplaint(data) {
    const formData = Util.getFormData(data);
    return http.post('api/v1/complaint', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },

  get(params) {
    return http.get(`/api/v1/complaint`, {params})
  }
}
