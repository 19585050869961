<template>
  <div class="form-select-container position-relative">
    <select
      v-model="inputVal"
      :id="id"
      class="form-select"
      @change="$emit('selected', selected)"
      :class="{
        'form-select-error': showError,
      }"
    >
      <option :value="null" selected>{{ defaultOption }}</option>
      <option
        v-for="item in items"
        :value="item.key ? item.key : item"
        :key="`st-${item.key ? item.key : item}`"
      >
        {{ item.label ? item.label : item }}
      </option>
    </select>

    <img
      src="@/core/assets/images/components/icon-select.svg"
      alt="Seleccionar"
      class="arrow-icon"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  props: {
    id: String,
    items: Array,
    defaultOption: String,
    showError: Boolean,
    value: [Object, Number, String]
  }
};
</script>

<style lang="stylus" scoped>
@import "../styles/components/form-select.styl"
</style>

